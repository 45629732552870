<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          page = 1;
          searchData;
        "
      >
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Coupon_Name')">
                <el-select
                  v-model.trim="formInline.couponRuleId"
                  filterable
                  size="15"
                  placeholder="请选择优惠券"
                  clearable
                >
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    :label="value.couponName"
                    :value="value.couponRuleId"
                    :key="value.couponRuleId"
                    v-for="value in couponList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  placeholder="请选择"
                  clearable
                >
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleListName"
                  ></el-option>
                </el-select>
              </el-form-item>

          <el-form-item :label="$t('searchModule.Coupon_type')">
            <el-select v-model.trim="formInline.couponType">
              <el-option label="全部" value />
              <el-option label="现金券" :value="0" />
              <el-option label="折扣券" :value="1" />
              <el-option label="全免券" :value="2" />
              <!-- <el-option label="小时券" :value="3" /> -->
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Collecting_Users')">
            <el-input v-model.trim="formInline.mobile" placeholder="请输入领取用户"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
            <el-autocomplete
              class="inline-input"
              v-model="modelvalue"
              :fetch-suggestions="querySearchAsyncCar"
              placeholder="请输入内容"
              value-key="parkName"
              :trigger-on-focus="false"
              @select="handleSelectCar"
            ></el-autocomplete>
          </el-form-item>

              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Usage_time')">
                <el-date-picker
                  v-model="date1"
                  type="datetime"
                  placeholder="选择日期"
                  @change="setStartTime"
                ></el-date-picker
                >至
                <el-date-picker
                  v-model="date2"
                  type="datetime"
                  placeholder="选择日期"
                  @change="setEndTime"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    let startTime = new Date(date1.getTime() - 3600 * 1000 * 24 * 30);
    // console.log("--startTime", startTime);
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      couponList: [],
      roleListName: [],
      exportData: {},
      date1: startTime,
      date2: endTime,
      initStartTime: startTime,
      initEndTime: endTime,
      modelvalue: "",
      plateNumber: "",
      tableCols: [
        {
          prop: "couponName",
          label: "优惠券名称",
          width: "",
        },
        {
          prop: "couponType",
          label: "优惠券类型",
          width: "",
          formatter: (row) => {
            if (row.couponType == 0) {
              return "现金券";
            } else if (row.couponType == 1) {
              return "折扣券";
            } else if (row.couponType == 2) {
              return "全免券";
            } else if (row.couponType == 3) {
              return "小时券";
            }
          },
        },
        {
          prop: "memberName",
          label: "领取用户",
          width: "",
        },
        {
          prop: "receivedTime",
          label: "领取时间",
          width: "",
          // formatter: row => {
          //   return this.payTypeList.filter(v => v.code === row.parkType)[0]
          //     .desc;
          // }
        },
        {
          prop: "distributeType",
          label: "领取渠道",
          width: "",
          formatter: (row) => {
            if (row.distributeType == 0) {
              return "活动领取";
            } else if (row.distributeType == 1) {
              return "手动派发";
            }
          },
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          width: "120",
        },
        {
          prop: "usedTime",
          label: "使用时间",
          width: "",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: "订单总额",
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.preferential_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "actualMoney",
          label: this.$t("list.Pay_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.actualMoney) {
              return Number(row.actualMoney / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "devTypeName",
          label: this.$t("list.Payment_devices"),
          width: "",
        },
      ],
      valueDate: "",
      member: "",
      formInline: {
        couponRuleId: "",
        operationId: "",
        couponType: "",
        mobile: "",
        parkId: "",
        // parkName: "",
        carId: "",
        // plateNumber: "",
        startTime: dateFormat(startTime, "yyyy-MM-dd HH:mm:ss"),
        endTime: dateFormat(endTime, "yyyy-MM-dd HH:mm:ss"),
        name: `优惠券核销明细`,
      },
      tableData: [],
      pageSize: 15,
      page: 1,
      loading: false,
      total: 0,
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleListName = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 优惠券
    getConList() {
      this.$axios
        .get("/acb/2.0/coupon/list", {
          data: {
            page: 1,
            pageSize: 100,
            status: 0,
          },
        })
        .then((res) => {
          // this.loading = false;
          if (res.state == 0) {
            this.couponList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        let diff = this.date2.getTime() - this.date1.getTime();
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("结束时间不能小于开始时间");
          return false;
        } else if (diff > 3600 * 1000 * 24 * 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    indexMethod(index) {
      return index + 1;
    },

    // 首页列表查询
    searchData() {
      // console.log("this=优惠券核销明细", this.formInline);
      // console.log(
      //   "时间",
      //   this.valueDate == null || this.valueDate == ""
      //     ? ""
      //     : dateFormat(this.valueDate[0], "yyyy-MM-dd")
      // );
      if (this.formInline.couponRuleId || this.formInline.mobile) {
      } else {
        let flag = this.showLog();
        if (!flag) {
          return;
        }
      }

      // couponid: "",
      // operationId: "",
      // couponType: "",
      // mobile: "",
      // parkId: "",
      // parkName: "",
      // carId: "",
      // plateNumber: "",
      // startTime: startTime,
      // endTime: endTime,

      this.$axios
        .get("/acb/2.0/couponStatistics/detail", {
          data: {
            page: this.page,
            size: this.pageSize,
            couponRuleId: this.formInline.couponRuleId,
            operationId: this.formInline.operationId,
            couponType: this.formInline.couponType,
            mobile: this.formInline.mobile,
            parkId: this.formInline.parkId,
            carId: this.formInline.carId,
            startTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value ? res.value.list : [];
            this.total = res.value ? res.value.total * 1 : 0;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      // console.log(
      //   "时间",
      //   this.valueDate == null || this.valueDate == ""
      //     ? ""
      //     : dateFormat(this.valueDate[0], "yyyy-MM-dd")
      // );
      let opt = {};
      opt = {
        pageNum: this.page,
        pageSize: this.pageSize,
        type: this.formInline.type,
        correctStartTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[0], "yyyy-MM-dd"),
        correctEndTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[1], "yyyy-MM-dd"),
        parkId: this.formInline.parkId,

        carId: this.formInline.carId,
      };

      // /acb/2.0/specialplate/export
      //   exportExcelNew("bacop/2.0/plateCorrectRecord/exportRecord", opt, "post");
    },
    querySearchAsync(queryString, cb) {
      this.formInline.plateNumber = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          }
        });
    },
    querySearchMember(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .post("/acb/2.0/memberInfo/query", {
          data: {
            nickName: queryString,
            page: 1,
            pageSize: 20,
            // sex: this.formInline.sex,
            // city: this.formInline.city,
            // authState: this.formInline.authState,
            // mobile: this.formInline.mobile,
            // nickName: this.formInline.nickName,
            startDate: "",
            endDate: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value.list.list);
          }
        });
    },
    querySearchAsyncCar(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue,
            slaveRelations: "0,1,2",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.temParkData = res.value.list.length
            //   ? res.value.list[0]
            //   : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            //  this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleMember(item) {
      // console.log("item", item);
      this.formInline.mobile = item.memberId;
      // this.page = 1;
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
      this.page = 1;
    },
    handleSelectCar(item) {
      this.formInline.parkId = item.parkId;
    },
    resetForm() {
      this.plateNumber = "";
      this.modelvalue = "";
      this.member = "";
      this.formInline = {
        couponRuleId: "",
        operationId: "",
        couponType: "",
        mobile: "",
        parkId: "",
        carId: "",
        startTime: dateFormat(this.initStartTime, "yyyy-MM-dd HH:mm:ss"),
        endTime: dateFormat(this.initEndTime, "yyyy-MM-dd HH:mm:ss"),
        name: `优惠券核销明细`,
      };
      this.date1 = this.initStartTime;
      this.date2 = this.initEndTime;

      this.exportData = {
        // /acb/2.0/payOrder/payOrderList/export
        url: "/acb/2.0/couponStatistics/detail/export",
        methods: "post",
        data: this.formInline,
      };
    },
  },
  components: {
    exportFile,
  },
  created() {
    this.getRoleList();
    this.getConList();
  },
  mounted() {
    // console.log("按钮权限为==>", this.$route.meta.authority);
    this.exportData = {
      // /acb/2.0/payOrder/payOrderList/export
      url: "/acb/2.0/couponStatistics/detail/export",
      methods: "post",
      data: this.formInline,
      // data: data,
    };
    this.searchData();
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
